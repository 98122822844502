<template>
  <div style="padding-bottom: 12px">
    <div class="right" v-if="isHr">
      <a-button type="primary" size="small" @click="add">添加</a-button>
    </div>
    <Padding />
    <a-table
      bordered
      :scroll="{x: 'max-content'}"
      :data-source="
        Array.isArray(detail.positionHisList) ? detail.positionHisList : []
      "
      :pagination="false"
      :row-selection="{
        type: 'radio',
        selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <a-table-column title="变动时间">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.changeTime"
          />
        </template>
      </a-table-column>
      <a-table-column title="归属部门">
        <template slot-scope="text, row, index">
          <a-select
            @change="onDeptChange(text, index)"
            show-search
            :filter-option="$selectFilterOption"
            style="width: 160px"
            size="small"
            placeholder="请选择"
            v-model="text.deptId"
          >
            <a-select-option
              v-for="item in organizationList"
              :key="item.id"
              :value="item.id"
              >{{ item.uniqueName }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <a-table-column title="所">
        <template slot-scope="text, row, index">
          <a-select
            @change="onPlaceChange($event, text, index)"
            show-search
            :filter-option="$selectFilterOption"
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.instituteId"
          >
            <a-select-option
              v-for="item in text.placeList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <!-- <a-table-column title="组">
        <template slot-scope="text">
          <a-select
            show-search
            :filter-option="$selectFilterOption"
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.groupId"
          >
            <a-select-option
              v-for="item in text.groupList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column> -->
      <a-table-column title="岗位">
        <template slot-scope="text">
          <a-select
            show-search
            :filter-option="$selectFilterOption"
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.position"
          >
            <a-select-option
              v-for="item in positionList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <a-table-column title="从事专业">
        <template slot-scope="text">
          <a-input
            style="width: 120px"
            size="small"
            placeholder="请输入"
            v-model="text.professionalize"
          />
        </template>
      </a-table-column>
      <a-table-column title="岗位序列">
        <template slot-scope="text">
          <a-input
            style="width: 60px"
            size="small"
            placeholder="请输入"
            v-model="text.postSeries"
          />
        </template>
      </a-table-column>
      <!-- <a-table-column title="职级">
        <template slot-scope="text">
          <a-input
            style="width: 60px"
            size="small"
            placeholder="请输入"
            v-model="text.rank"
          />
        </template>
      </a-table-column> -->
      <a-table-column title="职等">
        <template slot-scope="text">
          <a-input
            style="width: 60px"
            size="small"
            placeholder="请输入"
            v-model="text.grade"
          />
        </template>
      </a-table-column>
      <a-table-column title="任职开始日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.postStartTime"
          />
        </template>
      </a-table-column>
      <a-table-column title="任职结束日期">
        <template slot-scope="text">
          <a-date-picker
            style="width: 120px"
            size="small"
            placeholder="请选择"
            v-model="text.postEndTime"
          />
        </template>
      </a-table-column>
      <a-table-column title="状态">
        <template slot-scope="text">
          <a-select
            style="width: 60px"
            size="small"
            placeholder="请选择"
            v-model="text.status"
          >
            <a-select-option v-for="item in HrPositionHisStatus" :key="item.value" :value="item.value">{{ item.name }}
            </a-select-option>
          </a-select>
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center" v-if="isHr">
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
import organization from "@/mixins/organization";
import { fetchDetailTree } from "@/api/setting/organization";
import { mapGetters } from "vuex";
export default {
  mixins: [organization],
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    credentialType: {
      type: Array,
      default: () => [],
    },
  },
  data(){
    return {
      selectedRowKeys: [],
      placeList: [],
      groupList: [],
    }
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    ...mapGetters("auth", ["isHr"]),
    lists() {
      return this.findDataDict("talentMap");
    },
    // 员工类别
    HrPositionHisStatus() {
      return (
        this.lists.find((item) => item.value === "HrPositionHisStatus").children || []
      );
    },
    positionList() {
      return (
        this.lists.find((item) => item.value === "talentMap_post").children || []
      );
    },
  },
  mounted(){
    let index = 0;
    this.detail.positionHisList.forEach((v,i)=>{
      if(v.top){
        index = i
      }
    })
    this.selectedRowKeys = [index];
    this.detail.positionHisList = this.detail.positionHisList.map(v=>{
      return {
        ...v,
        placeList: [],
        groupList: []
      }
    })
    this.detail.positionHisList.forEach((arr, index) => {
      if(arr.deptId){
        fetchDetailTree({
          uuid: arr.deptId,
        }).then((res) => {
          if (res) {
            this.detail.positionHisList[index].placeList = Array.isArray(res.instituteVoList)
              ? res.instituteVoList
              : [];
            
            if (arr.instituteId) {
              const item = this.detail.positionHisList[index].placeList.find((item) => item.id === arr.instituteId);
              if (item) {
                this.detail.positionHisList[index].groupList = Array.isArray(item.groupVoList)
                  ? item.groupVoList
                  : [];
              }
            }
            console.log(this.detail.positionHisList[index])
          }
        });
      }
    });
    
  },
  methods: {
    onSelectChange(keys) {
      console.log(keys)
      this.selectedRowKeys = keys;
      const obj = this.detail.positionHisList[keys[0]];
      this.$emit("lineSelect", obj, keys[0]);
    },
    add() {
      if (Array.isArray(this.detail.positionHisList)) {
        this.detail.positionHisList.push({
          deptId: '',
          instituteId: '',
          groupId: '',
          placeList: [],
          groupList: []
        });
      } else {
        this.detail.positionHisList = [{
          deptId: '',
          instituteId: '',
          groupId: '',
          placeList: [],
          groupList: []
        }];
      }
    },
    remove(index) {
      this.detail.positionHisList.splice(index, 1);
    },
    onDeptChange(text, index) {
      this.placeList = [];
      this.groupList = [];
      this.detail.positionHisList[index].instituteId = '';
      fetchDetailTree({
        uuid: text.deptId,
      }).then((res) => {
        if (res) {
          this.detail.positionHisList[index].placeList = Array.isArray(res.instituteVoList)
            ? res.instituteVoList
            : [];
        }
      });
    },
    onPlaceChange(value, text, index) {
      this.detail.positionHisList[index].groupId = '';
      const item = text.placeList.find((item) => item.id === value);
      if (item) {
        this.detail.positionHisList[index].groupList = Array.isArray(item.groupVoList)
          ? item.groupVoList
          : [];
      }
    },
  },
};
</script>